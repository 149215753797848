@import url(https://fonts.googleapis.com/css2?family=Muli&display=swap);
/* for mobile view  */
@media only screen and (max-width: 600px) {
  #content1::after{
    content:"" !important;
    white-space: pre !important;
  }
  .main-row{
    background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); 
   }
  .login-page-container {
    /* background-image: url("/img/Login screen background image.jpg"); */
  /* Full height */
    height: 100vh;
    width: 100vw;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }
  
  
  .indusind-welcome-text-mobile-view{
    color: var(--secondaryColor);
    font-weight: 900 !important;
    font-size: 32px !important;
    text-align: center !important;
    margin-left: 18% !important;
  }
  .indusind-welcome-text {
    color: var(--secondaryColor);
    font-weight: 900;
    font-size: 65px;
  }
  
  .indusind-welcome-ev-text {
    color: #000;
    font-weight: 500;
    font-size: 20px !important;
    margin-left: 23%;
  }
  
  .indusind-text-container {
    position: absolute !important;
    width: 80% !important;
    line-height: normal !important;
    margin-top: 16vh !important;
    margin-left: 18% !important;
}

  .indusind-powered-by-container {
    padding-top: 0 !important;
    margin-left: 2rem !important;
  }
  
  .indusind-login-container{
     display:flex;
     /* Full height */
      height: 100vh;
      width: 100vw;
    
      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
    
      background-size: cover;
    
      /* display: flex; */
      /* justify-content: center; */
  }
  
  
  
  .indusind-login-container {
    /* background-image: url("/img/IBL/BG 2-13.png"); */
      display: flex;
     /* Full height */
    height: 106vh;
    width: 100vw;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
  }
   
  .container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
  
  }
  .ibl-signin-btn {
    width: 120px !important;
    font-weight: 500 !important;
    margin-left: -18% !important;
    letter-spacing: 1px !important;
  }
  .indusind-sign-in-button{
    margin-top: 97%;
    margin-left: 38%;
  }
  .powered-by-text img{
      width: 35vw !important;
    }
 
  .indusind-welcome-ev-text-employee{
    margin-left: 6% !important;
    font-size: 20px !important;
  }
  
.powered-by-text {
  margin-top: 2vh;
  margin-bottom: -1vh;
  font-size: var(--themeHeading3);
  font-weight: 900;
  margin-left: 1%;
}
 .md-speech-message-mobile-view {
  /* display: block !important;
  width: 152%;
  margin-top: 39%;
  margin-left: -53%; */
  } 
 .md-speech-message{
  /* display: none; */
  display: block !important;
    width: 71% !important;
    margin-top: 26vh !important;
    margin-left: 16vw !important;
  }

 .md-message-container{
  /* background-color:#E3E7EB; */
  /* background-color: #f8f8f8; */
  /* background:linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); */
  }
  .row{
    margin-left: -15px !important;
  } 
}

 /* for tablets */
 @media only screen and (min-width: 600px) {
  .indusind-text-container {
    position: absolute;
    width: 50% !important;
    margin: 20% 10% !important;
    line-height: normal;
}
#signinSSO {
  margin-top: 1%;
  text-align: center !important;
}
.indusind-powered-by-container {
  margin-left: 3vw;
  padding-top: 26px !important;
}
.row{
  margin-left: 0 !important;
} 
  .md-speech-message{
    width: 43vw !important;
    margin-left: 57vw !important;
    margin-top: 20vh !important;
    /* background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); */
  }
  .indusind-welcome-ev-text{
    color: #000;
    font-size: 40px !important;
    font-weight: 500;
  }
  .indusind-welcome-text {
    color: var(--secondaryColor);
    font-weight: 900;
    font-size: 55px !important;
  }
}
@media only screen and (min-width: 992px) {

.login-page-container {
  height: 100vh;
  width: 100vw;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.indusind-welcome-text {
  color: var(--secondaryColor);
  font-weight: 900;
  font-size: 60px;
}

.indusind-welcome-ev-text {
  color: #000;
  font-size: 45px;
  font-weight: 500;
}

.indusind-text-container {
  position: absolute;
  width: 50% !important;
  margin: 15% 13% !important;
  line-height: normal;
}

.indusind-powered-by-container {
  padding-top: 50px !important;
  margin-left: 0 !important;
}

.md-message-container{
  /* background:linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); */
   background-repeat: no-repeat;
   
  }

  .indusind-login-container {
  display: flex;
  /* Full height */
  height: 100vh;
  width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  /* display: flex; */
  /* justify-content: center; */
  background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB);
}


.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;

}

#signinSSO {
  margin-top: 1%;
  text-align: center !important;
}

.sso-signup-header {
  font-weight: 700;
  margin-top: -7.5vh;
  margin-bottom: -7.5vh;
  font-size: var(--themeHeading2);
}

.login-div {
  width: 47.5vw;
  height: 95vh;
  background-color: #f5eded;
  opacity: 0.95;
  margin: auto;
  /* margin-top: 5vh; */
  border-radius: 25px;
  box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  padding: 0 45px;
}

.sso-login-div {
  width: 30vw;
  height: 75vh;
  background-color: #f5eded;
  opacity: 0.95;
  margin: auto;
  /* margin-top: 15vh; */
  /* margin-bottom: 5vh; */
  border-radius: 25px;
  box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  padding: 0 45px;
}

.swal-scollable-div {
  overflow-y: auto;
  height: 60vh;
  font-size: var(--themeSmall);
}

.privacy-notice {
  cursor: pointer;
  margin-top: 2vh;
  text-decoration: underline;
  font-size: var(--themeSmall);
}

.powered-by-text {
  margin-top: 2vh;
  margin-bottom: -1vh;
  font-size: var(--themeHeading3);
  font-weight: 700;
}

.signup-container .signup-header {
  padding: 0px;
  /* font-size: 20px; */
  font-size: var(--themeHeading2);
  font-weight: 700;
  padding-top: 10px;
  text-align: center;
  letter-spacing: 2px;
}

.login-div>.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.login-form>div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 80%;
}

.login-form>div>input {
  border: none;
  border-bottom: 2px solid #b0bdbe;
  outline: none;
}

.login-form>div>input:focus {
  border-bottom: 2px solid #25dabb;
}

.login-form>button {
  background-color: var(--button-color);
  width: 80%;
  border-radius: 5px;
  color: white;
  padding: 5px 0;
  border: none;
  outline: none;
}

.login-form>button:hover {
  border: 2px solid var(--button-color);
  color: var(--button-color);
}

.alerts {
  position: fixed;
  top: 32px;
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  left: 0;
  z-index: 2024;
}

.alert-close-btn {
  height: 49px;
  background: #abdcb7;
  border: none;
  color: red;
  font-size: 2rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}

.md-speech-message{
  /* width: 77% !important;
  margin-left: 23% !important;
  margin-top: 2% !important; */
  /* width: 48% !important;
  margin-left: 79% !important;
  margin-top: 8% !important;
  background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); */
  width: 38vw !important;
  margin-left: 60vw !important;
  margin-top: 15vh !important;
  /* background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); */
}
.md-speech-message-mobile-view{
  display: none;
}
#content1::after{
  content: "\a";
  white-space: pre;
}
.row{
  margin-left: 0 !important;
} 
.main-row{
  background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB); 
 }
 .mobile-view{
  display: none;
 }
}


 /* @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
    {
    .md-speech-message{
      width: 40% !important;
      margin-left: 55% !important;
      margin-top: 10% !important;
      background: linear-gradient(#f8f8f8,#f8f8f8,#E3E7EB);
    }
    .powered-by-text {
      margin-top: -7% !important;
      margin-left: 5% !important;
      /* margin-bottom: -1vh; */
      /* font-size: var(--themeHeading3);
      font-weight: 700;
  }
  .indusind-text-container {
    position: absolute;
    width: 50% !important;
    margin: 13% 8% !important;
    line-height: normal;
}


} */ 







/* @media only screen and (min-width: 1200px) { */
  


  


/* } */

/*---------------------------------- LOGIN SCREEN ---------------------------------- */




.NotFound
{
    font-family: 'Open Sans', sans-serif;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    height: 100vh;

    color: #fff;
    background: #f8cb5e ;
}

.NotFound .c
{
    position: relative;

    display: block;

    width: 80%;
    margin: 100px auto;

    text-align: center;
}

.NotFound ._404
{
    font-size: 220px;

    position: relative;
    z-index: 2;

    display: inline-block;

    height: 250px;

    letter-spacing: 15px;
}

.NotFound ._1
{
    font-size: 4em;
    line-height: 80%;

    position: relative;

    display: block;

    text-align: center;
    letter-spacing: 12px;
}

.NotFound ._2
{
    font-size: 20px;

    position: relative;

    display: block;

    text-align: center;
}

.NotFound .text
{
    font-size: 70px;
    line-height: 1.2em;

    position: relative;
    z-index: 3;

    display: inline-block;

    width: 100%;
    margin: 19px 0 0 0;

    text-align: center;
}

.NotFound .btn-nf
{
    font-size: 25px;

    position: relative;
    z-index: 5;

    display: inline-block;

    width: 358px;
    margin: 0 auto;
    margin-right: 10px;
    padding: 5px;

    text-decoration: none;

    color: #f8cb5e ;
    background-color: rgb( 255, 255, 255);
}

.NotFound .right
{
    float: right;

    width: 60%;
}

.NotFound hr
{
    width: 420px;
    height: 10px;
    margin: 0 auto !important;
    padding: 0;

    text-align: center;

    color: #fff;
    border: none;
    border-top: 5px solid #fff;
}

.verify
{
    font-family: 'Open Sans', sans-serif;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    height: 100vh;

    color: #fff;
    background: $themeColor;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify-container
{
    text-align: center;
    padding: 20px;
}

.verify-1
{
    font-size: 2.5em;

    position: relative;

    display: block;

    text-align: center;
    letter-spacing: 12px;
    line-height: 1;
}

.verify-2
{
    font-size: 26px;

    position: relative;

    display: block;

    text-align: center;
    margin: 20px 0;
    font-weight: lighter;
}

.text
{
    font-size: 70px;
    line-height: 1.2em;

    position: relative;
    z-index: 3;

    display: inline-block;

    width: 100%;
    margin: 19px 0 0 0;

    text-align: center;
}

.verify-btn-nf
{
    font-size: 25px;

    position: relative;
    z-index: 5;

    display: inline-block;

    width: 358px;
    margin: 0 10px 0 auto;
    padding: 5px;

    text-decoration: none;

    color: $themeColor ;
    background-color: rgb( 255, 255, 255);
}

.right
{
    float: right;

    width: 60%;
}

hr
{
    width: 420px;
    height: 10px;
    margin: 0 auto;
    padding: 0;

    text-align: center;

    color: #fff;
    border: none;
    border-top: 5px solid #fff;
}


/*Loader*/
html {
    box-sizing: border-box;
    font-family: "Lato, sans-serif";
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    font-family: 'Lato', sans-serif;
    color: #4d4d4d;
}

body {
    width: 100%;
    height: 100%;
    font-size: 1.6em;
}

.loader-wrapper {
    font-size: 1.6rem;
    background: #dad8d2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.hourglass {
    display: block;
    background: #dad8d2;
    margin: 3em auto;
    width: 2em;
    height: 4em;
    box-shadow: inset #dad8d2 0 0 0 0, inset $themeColor 0 2em 0 0, inset #dad8d2 0 0 4em 0;
    animation: hourglass 1s linear infinite;
}

.outer {
    fill: $themeColor;
}

.middle {
    fill: #dad8d2;
}

@keyframes hourglass {
    0% {
        transform: rotate(0deg);
        box-shadow: inset #dad8d2 0 0 0 0, inset $themeColor 0 2em 0 0, inset #dad8d2 0 4em 0 0, inset $themeColor 0 4em 0 0;
    }
    80% {
        transform: rotate(0deg);
        box-shadow: inset #dad8d2 0 2em 0 0, inset $themeColor 0 2em 0 0, inset #dad8d2 0 2em 0 0, inset $themeColor 0 4em 0 0;
    }
    100% {
        transform: rotate(180deg);
        box-shadow: inset #dad8d2 0 2em 0 0, inset $themeColor 0 2em 0 0, inset #dad8d2 0 2em 0 0, inset $themeColor 0 4em 0 0;
    }
}


 @media only screen and (max-width: 600px) {
 .box {
        display: inline-block;
        padding: 10px;
        box-sizing: border-box;
      }
      .img-preview {
        overflow: hidden;
      
    }
    .crop-image-conatiner {
     display: grid !important;
    grid-template-columns: 35% 8% 38% !important;
    }
    .crop-image-conatiner>div {
        height: 10vh !important;
        width: 15vw  !important;
    
    }
    .crop-image-conatiner~div >img {
        height:100% !important;
        width:100% !important;
    
    }
    .img-preview >img {
        display: block;
        height: 10vh !important;
        width: 15vw !important;
        min-width: 0px !important;
        min-height: 0px !important;
        max-width: none !important;
        max-height: none !important;
        transform: none !important;
    }
    
    .image-conatiner-preview >img {
        height: 9rem;
        width: 9rem;
        /* border-radius: 50%; */
        /* /* border: 1px solid black; */
        margin-left: 7vw;
    }
    
    .file-input-button{
        display: flex;
    }
    .cropper-hidden {
        height:10vh !important;
        width: 15vw !important;
    }
    
    .cropper-container{
        height: 10vh !important;
        width: 46vw !important;
    }
    .main-image-cropper-container{
        height:10vh;
        margin-top: 2px;
    }
    .checklist-for-image-crop{
        margin-top:6.2em;
        cursor: pointer;
    }
    .cropper-modal{
        bottom: 0;
        left: -13px !important;
        position: absolute;
        right: -90px !important;
    }
    .checklist-for-image-crop{
        margin-top: 4.9em !important;
    cursor: pointer ;
    margin-left: 47px;

    }
    .image-conatiner-preview{
        margin-left: 33px

    }



} 



.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  .img-preview {
    overflow: hidden;
  
}
.crop-image-conatiner>div {
    height: 10vh !important;
    width: 15vw  !important;

}
.crop-image-conatiner~div >img {
    height:100% !important;
    width:100% !important;

}
.img-preview >img {
    display: block;
    height: 10vh !important;
    width: 15vw !important;
    min-width: 0px !important;
    min-height: 0px !important;
    max-width: none !important;
    max-height: none !important;
    transform: none !important;
}

.image-conatiner-preview >img {
    height: 11rem;
    width: 11rem;
    /* border-radius: 50%; */
    border: 1px solid gray;
    margin-left: 4vw;
}

.file-input-button{
    display: flex;
}
.cropper-hidden {
    height:10vh !important;
    width: 15vw !important;
}

.cropper-container{
    height: 17vh !important;
        width: 19vw !important;
}
.main-image-cropper-container{
    height:10vh;
    margin-top: 2px;
}
.checklist-for-image-crop{
    margin-top: 5.2em;
    cursor: pointer;
    margin-left: 4.5em;
}
 h1 {
     /* margin: 50px 0 30px; */
     text-align: center;
     align-items: center;
     color: var(--themeColor) !important;
     font-size: var(--themeHeading1) !important;
 }

 .faq-container {
     max-width: 600px;
     margin: 0 auto;
 }

 .faq {
     background-color: transparent;
     border: 1px solid #9fa4a8;
     border-radius: 10px;
     margin: 20px 0;
     padding: 30px;
     position: relative;
     overflow: hidden;
     transition: 0.3s ease;
 }

 .faq.active {
     background-color: #fff;
     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
 }

 .faq.active::before,
 .faq.active::after {
     content: '\f075';
     font-family: 'Font awesome 5 free';
     color: #2ecc71;
     font-size: 7rem;
     position: absolute;
     opacity: 0.2;
     top: 20px;
     left: 20px;
     z-index: 0;
 }

 .faq.active::before {
     color: #3498db;
     top: -10px;
     left: -30px;
     transform: rotateY(180deg);
 }

 .faq-title {
     margin: 0 35px 0 0;
    display: flex;
    line-height: 1.4;
    align-items: center;
    color: var(--themeColor) !important;
    font-size: var(--themeHeading2) !important;
 }

 .faq-text {
     display: none;
     margin: 15px 0 0;
     font-size: var(--themeBodyFontSize);
 }

 .faq.active .faq-text {
     display: block;
 }

 .faq-toggle {
     background-color: transparent;
     border-radius: 50%;
     border: 0;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 16px;
     padding: 0;
     position: absolute;
     top: 30px;
     right: 30px;
     width: 30px;
     height: 30px;
 }

 .faq-toggle:focus {
     outline: none;
 }

 .faq-toggle .fa-angle-double-up {
     display: none;
 }

 .faq.active .faq-toggle .fa-angle-double-up {
     display: block;
     color: white;
 }

 .faq.active .faq-toggle .fa-angle-double-down {
     display: none;
 }

 .faq.active .faq-toggle {
     background-color: #9fa4a8;
 }
