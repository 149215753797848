 @import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');



 h1 {
     /* margin: 50px 0 30px; */
     text-align: center;
     align-items: center;
     color: var(--themeColor) !important;
     font-size: var(--themeHeading1) !important;
 }

 .faq-container {
     max-width: 600px;
     margin: 0 auto;
 }

 .faq {
     background-color: transparent;
     border: 1px solid #9fa4a8;
     border-radius: 10px;
     margin: 20px 0;
     padding: 30px;
     position: relative;
     overflow: hidden;
     transition: 0.3s ease;
 }

 .faq.active {
     background-color: #fff;
     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
 }

 .faq.active::before,
 .faq.active::after {
     content: '\f075';
     font-family: 'Font awesome 5 free';
     color: #2ecc71;
     font-size: 7rem;
     position: absolute;
     opacity: 0.2;
     top: 20px;
     left: 20px;
     z-index: 0;
 }

 .faq.active::before {
     color: #3498db;
     top: -10px;
     left: -30px;
     transform: rotateY(180deg);
 }

 .faq-title {
     margin: 0 35px 0 0;
    display: flex;
    line-height: 1.4;
    align-items: center;
    color: var(--themeColor) !important;
    font-size: var(--themeHeading2) !important;
 }

 .faq-text {
     display: none;
     margin: 15px 0 0;
     font-size: var(--themeBodyFontSize);
 }

 .faq.active .faq-text {
     display: block;
 }

 .faq-toggle {
     background-color: transparent;
     border-radius: 50%;
     border: 0;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 16px;
     padding: 0;
     position: absolute;
     top: 30px;
     right: 30px;
     width: 30px;
     height: 30px;
 }

 .faq-toggle:focus {
     outline: none;
 }

 .faq-toggle .fa-angle-double-up {
     display: none;
 }

 .faq.active .faq-toggle .fa-angle-double-up {
     display: block;
     color: white;
 }

 .faq.active .faq-toggle .fa-angle-double-down {
     display: none;
 }

 .faq.active .faq-toggle {
     background-color: #9fa4a8;
 }