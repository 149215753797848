
 @media only screen and (max-width: 600px) {
 .box {
        display: inline-block;
        padding: 10px;
        box-sizing: border-box;
      }
      .img-preview {
        overflow: hidden;
      
    }
    .crop-image-conatiner {
     display: grid !important;
    grid-template-columns: 35% 8% 38% !important;
    }
    .crop-image-conatiner>div {
        height: 10vh !important;
        width: 15vw  !important;
    
    }
    .crop-image-conatiner~div >img {
        height:100% !important;
        width:100% !important;
    
    }
    .img-preview >img {
        display: block;
        height: 10vh !important;
        width: 15vw !important;
        min-width: 0px !important;
        min-height: 0px !important;
        max-width: none !important;
        max-height: none !important;
        transform: none !important;
    }
    
    .image-conatiner-preview >img {
        height: 9rem;
        width: 9rem;
        /* border-radius: 50%; */
        /* /* border: 1px solid black; */
        margin-left: 7vw;
    }
    
    .file-input-button{
        display: flex;
    }
    .cropper-hidden {
        height:10vh !important;
        width: 15vw !important;
    }
    
    .cropper-container{
        height: 10vh !important;
        width: 46vw !important;
    }
    .main-image-cropper-container{
        height:10vh;
        margin-top: 2px;
    }
    .checklist-for-image-crop{
        margin-top:6.2em;
        cursor: pointer;
    }
    .cropper-modal{
        bottom: 0;
        left: -13px !important;
        position: absolute;
        right: -90px !important;
    }
    .checklist-for-image-crop{
        margin-top: 4.9em !important;
    cursor: pointer ;
    margin-left: 47px;

    }
    .image-conatiner-preview{
        margin-left: 33px

    }



} 



.box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  .img-preview {
    overflow: hidden;
  
}
.crop-image-conatiner>div {
    height: 10vh !important;
    width: 15vw  !important;

}
.crop-image-conatiner~div >img {
    height:100% !important;
    width:100% !important;

}
.img-preview >img {
    display: block;
    height: 10vh !important;
    width: 15vw !important;
    min-width: 0px !important;
    min-height: 0px !important;
    max-width: none !important;
    max-height: none !important;
    transform: none !important;
}

.image-conatiner-preview >img {
    height: 11rem;
    width: 11rem;
    /* border-radius: 50%; */
    border: 1px solid gray;
    margin-left: 4vw;
}

.file-input-button{
    display: flex;
}
.cropper-hidden {
    height:10vh !important;
    width: 15vw !important;
}

.cropper-container{
    height: 17vh !important;
        width: 19vw !important;
}
.main-image-cropper-container{
    height:10vh;
    margin-top: 2px;
}
.checklist-for-image-crop{
    margin-top: 5.2em;
    cursor: pointer;
    margin-left: 4.5em;
}